export default {
    methods: {
        async loadData(globaluserid, pageNo, pageSize, callback) {
            this.$store.dispatch('getMyActivitys', {
                globaluserid: globaluserid,
                pageNo: pageNo,
                pageSize: pageSize,
             })
             .then((rs) => {
                var list = rs.list || []
                var total = rs.total || 0
                list.forEach((item)=> {
                    var auditstatus = item.auditstatus
                    if(auditstatus == 0) {
                        item._statusText = '審核中'
                        item._levelText = 'level0'
                    } else if(auditstatus == 1) {
                        item._statusText = '報名成功'
                        item._levelText = 'level1'
                    } else if(auditstatus == 2) {
                        item._statusText = '被拒绝'
                        item._levelText = 'level2'
                    } else {
                        item._statusText = '已過期'
                        item._levelText = 'level3'
                    }
                })
                callback && callback({list, total}, null)
            }).catch((rs) => {
                callback && callback(null, rs)
            })
        }
    }
}