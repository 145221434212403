<template>
	<div class="tab-view">
		<div class="menus">
			<router-link to="/user/association/myActivity" class="menu current">我的活動(<span>{{total}}</span>)</router-link>
		</div>
		<div class="tabview-content">
            <div class="my-activity-list">
                <div :class="{'my-activity-item': true, [item._levelText]: true}" v-for="item in dataList" :key="item.activityid">
                    <div class="activity-name" v-text="item.title"></div>
                    <div class="activity-time" v-text="item.starttime + '~' + item.endtime"></div>
                    <div class="row">
                        <div class="activity-code" v-text="'參與碼：' + item.regcode"></div>
                        <a :href="item.activitydetailurl" class="btn-detail">活動詳情</a>
                        <div class="btn-upload" @click="showUpload(item)">上傳憑證</div>
                        <a class="preview-img" v-if="item.regAttachment" :href="item.regAttachment" target="_blank" title="憑證">
                            <img :src="item.regAttachment" alt="">
                        </a>
                    </div>
                    <div class="status">{{item._statusText}}</div>
                </div>
            </div>
            <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
            <loading v-if="loading"/>
		</div>
        <activity-file v-if="isUpload" :callback="onUploadResult" :activityId="mActivity.activityregid"/>
	</div>
</template>

<script>
    import ActivityFile from '@/layouts/ActivityFile.vue'
    import my_activity from '@/views/mixins/my_activity'
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
	export default {
        mixins: [
            my_activity,
        ],
        components: {
            ActivityFile,
            Loading,
            SplitPage,
        },
		mounted: function(){
            this.onRefresh()
		},
		data() {
            return {
                mActivity: null,
                userInfo: this.$store.getters.userInfo,
                loading: true,
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                visible: false,
                isUpload: false,
                style: '',
            }
        },
        created: function(){
            if(! this.userInfo) {
                this.$router.push('/')
                return
            }
            if(! this.userInfo.doctorglobaluserid) {
                this.$router.push('/user')
                return
            }
        },
        methods: {
            showUpload: function(activity){
                this.mActivity = activity
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
                this.isUpload = true
            },
            onUploadResult: function(){
                this.isUpload = false
				document.body.setAttribute('style', this.style)
            },
            onRefresh: function() {
                this.pageNo = 1
                this.loading = true
                this.loadData(this.userInfo.doctorglobaluserid, this.pageNo, this.pageSize, (data, error)=>{
                    if(data) {
                        this.dataList = data.list
                        this.total = data.total
                        this.visible = this.total > 0
                        this.loading = false
                    } else {
                        this._toast.warning(this, error)
                        this.loading = false
                    }
                })                
            },
            onPageChanged: function(index) {
                this.pageNo = index
                this.loading = true
                this.loadData(this.userInfo.doctorglobaluserid, this.pageNo, this.pageSize, (data, error)=>{
                    if(data) {
                        this.dataList = data.list
                        this.total = data.total
                        this.loading = false
                    } else {
                        this._toast.warning(this, error)
                        this.loading = false
                    }
                }) 
            }
        }
	};
</script>

<style lang="scss" scoped>

    .tab-view {
		width: 1000px;
		display: flex;
		flex-direction: row;
		margin: 0px auto 40px auto;
		position: relative;
	}

	.tab-view > .menus {
		display: flex;
		flex-direction: column;
		width: 164px;
		margin-right: 10px;
		margin-top: -1px;
	}

	.tab-view > .menus > .menu {
		display: block;
		width: 164px;
		height: 50px;
		margin-top: 1px;
		background-color: #fff;
		line-height: 50px;
		text-align: center;
		font-weight: 400;
		color: #969696;
		font-size: 16px;
	}

	.tab-view > .menus > .menu.current {
		color: #36C4D0;
		font-weight: bold;
		position: relative;
	}

	.tab-view > .menus > .menu.current::after {
		content: '';
		position: absolute;
		right: 10px;
		top: 20px;
		width: 7px;
		height: 10px;
		background-image: url(../../../../assets/imgs/pc/user/img_more@2x.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.tab-view > .tabview-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		min-height: 466px;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 60px;
    }

    .my-activity-list {
        display: flex;
        flex-direction: column;
    }

    .my-activity-list .my-activity-item {
        display: flex;
        flex-direction: column;
        width: 756px;
        margin-left: 20px;
        margin-top: 28px;
        height: 148px;
        background:rgba(255,255,255,1);
        box-shadow:0px 2px 8px 0px rgba(232,234,243,1);
        border-radius:16px;
        padding: 22px 18px 30px 20px;
        box-sizing: border-box;
        position: relative;
    }

    .my-activity-list .my-activity-item.level2,
    .my-activity-list .my-activity-item.level3 {
        background: rgba(232,232,232,1);
        box-shadow: 0px 2px 8px 0px rgba(232,234,243,1);
    }

    .my-activity-list .my-activity-item > .activity-name {
        color: #231F20;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
    }

    .my-activity-list .my-activity-item.level2 > .activity-name,
    .my-activity-list .my-activity-item.level3 > .activity-name {
        color: #969696;
    }

    .my-activity-list .my-activity-item > .activity-time {
        color: #969696;
        margin-top: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
    }

    .my-activity-list .my-activity-item > .row {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
    }

    .my-activity-list .my-activity-item .activity-code {
        color: #231F20;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        margin-right: 255px;
    }

    .my-activity-list .my-activity-item.level2 .activity-code,
    .my-activity-list .my-activity-item.level3 .activity-code {
        color: #969696;
    }
        
    .my-activity-list .my-activity-item .btn-upload,
    .my-activity-list .my-activity-item .btn-detail {
        color: #969696;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        position: relative;
        padding-left: 25px;
        margin-right: 37px;
        cursor: pointer;
    }
    .my-activity-list .my-activity-item .preview-img {
        width: 60px;
        height: 36px;
        position: absolute;
        right: 23px;
        bottom: 23px;
    }

    .my-activity-list .my-activity-item .preview-img > img {
        width: 100%;
        height: 100%;
    }

    .my-activity-list .my-activity-item .btn-upload::after,
    .my-activity-list .my-activity-item .btn-detail::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 16px;
        background-image: url(../../../../assets/imgs/pc/user/img_huod@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .my-activity-list .my-activity-item .btn-upload::after {
        background-image: url(../../../../assets/imgs/pc/user/img_pingz@2x.png);
    }

    .my-activity-list .my-activity-item.level2 .btn-detail,
    .my-activity-list .my-activity-item.level3 .btn-detail {
        color: #231F20;
    }

    .my-activity-list .my-activity-item.level2 .btn-upload,
    .my-activity-list .my-activity-item.level3 .btn-upl     oad {
        cursor: no-drop;
    }
    
    .my-activity-list .my-activity-item .status {
        width: 86px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #2A8737;
        font-size: 16px;
        font-weight: bold;
        background-color: #C5E6C7;
        position: absolute;
        top: 20px;
        right: 18px;
        border-radius: 24px;
    }

    .my-activity-list .my-activity-item.level1 .status{
        background-color: #EAF1F7;
        color: #3E607C;
    }

    .my-activity-list .my-activity-item.level2 .status {
        background-color: #fff;
        color: #231F20;
    }

    .my-activity-list .my-activity-item.level3 .status {
        background-color: #fff;
        color: #969696;
    }

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}
</style>
